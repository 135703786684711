import $ from "jquery"

window.jQuery = $
window.$ = $

var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy"
});

$(function () {
	$('#menu-toggle').click(function(){
        $(this).toggleClass('open');
        $('#menu').toggleClass('shown');
    });
})